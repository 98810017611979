*{
  margin:0;
  padding: 0;
  box-sizing: border-box;

}
html{
  font-size: 70%;
}
.app{
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(to bottom, #040108, #2a8890);
  padding: 30px;
  color: white;
}
.programmsContainer{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: auto;
  align-items: center;
  /* align-items: baseline; */
  justify-content: center;

}

.app__header{
 
  font-size: 5rem;
  color: white;
  text-align: center;
 
}

input{
  width: 100%;
  padding: 15px;
  margin: 5px;
  border-radius: 25px 50px;
  font-size: var(--main-font-size);
  outline: none;
  border: 3px solid transparent;
 
  transition: all ease-in 0.1s;
}

input:focus{
  border: 3px solid rgb(1, 1, 41);
}

.info{
  display: grid;
  place-items: center;
}