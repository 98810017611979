.card {
  width: 300px;
  background-color: #f5f5f5;
  border-radius: 15px;
  margin: 10px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  color:rgba(0, 0, 0);
  font-size: 1.3rem;
}

.card-header {
  margin-bottom: 10px;
}

.card-header h3 {
  margin: 0;
  font-size: 1.25em;
  font-weight: bold;
}

.card-body p {
  margin: 0;
  margin-bottom: 5px;
  font-size: 0.9em;
}

.card-image {
  width: 100%;
  height: 80px;
  /* object-fit: cover; */
  object-fit: contain;
  border-radius: 25px;
}
.card-image img{
  border-radius: 25px;
}
.card__reward{
  color: green;
  font-weight: 900;
  
}

.card__readMore{
  color:rgba(11, 6, 79);
  font-weight: 900;
  cursor:cell;
}

.card__terms{
  color:rgb(255, 38, 0)
}