.rectButton{
    width: 100%;
    display: block;
    margin: 10px 0;
    padding: 10px 20px;
    background-color: rgb(1, 1, 41);
    color: white;
    border-radius: 15px;
    outline: none;
    border:none;
    cursor: pointer;
}